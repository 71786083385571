.svg-width {
  width: 350px;
}

.container {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}
