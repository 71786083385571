@font-face {
  font-family: 'nasalizationregular';
  src: url('nasalization-rg-webfont.woff2') format('woff2'),
       url('nasalization-rg-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
strong{
  color: #eb5939;
  font-size: x-large;
  letter-spacing: 1px;
}
:root {
  --light-color: #fff;
  --dark-color: #000;
}
.dark {
  --font-color: #fff;
  --bg-color: #1c1717;
  --sidenav-bg: #000;
  --p-font-color: #e1e1e1;
  --skill-bg-color: #000;
  --skill-font: #efefef;
  --service-bg: #323232;
  --number-bg: #473C33;
  --facts-bg: #000;
  --border: #fdfdfd;
  --contact-form-border: #6a6464;
  --button-color: #000;
  --about-border: #dfdfdf;
  --year: #000;
  transition:  .5s ;
}
.light {
  --font-color: #000;
  --bg-color: #f7f7f7;
  --sidenav-bg: #F0EBE3;
  --p-font-color: #000;
  --skill-bg-color: #EEEDEB;
  --skill-font: #000;
  --service-bg: #EEEDEB;
  --number-bg:   #f8f8f8;
  --facts-bg: #ebebeb;
  --border: #000;
  --contact-form-border: #6a6464;
  --button-color: #fff;
  --about-border: #000;
  --year: #F7EFE5;
  transition:  .5s ;
}
p,
h1,
span,
h3 {
  color: var(--font-color);
}
.border{
  border-radius: 7px;
}
body {
  font-family: sans-serif;
  color: var(--light-color);
  margin: 0;
}
textarea::placeholder {
  color: #fff;
}
#root {
  display: flex;
}
.side-nav-container {
  background-color: var(--sidenav-bg);
  position: fixed;
  top: -16px;
  left: 0;
  /* height: 47.4rem; */
  width: 16rem;
}
.side-nav-container ul {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  list-style: none;
  height: auto;
  padding: 15rem 0;
  align-items: center;
}
.side-nav-container a {
  color: var(--font-color);
  text-decoration: none;
  transition: letter-spacing 0.5s;
  font-family: "Kanit", sans-serif;
}
.side-nav-container a:hover {
  letter-spacing: 5px;
}

.open-nav {
  left: -7px !important;
}
.mobile-nav ul {
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 4rem;
}
.mobile-nav a {
  color: var(--font-color);
  text-decoration: none;
  font-family: "Kanit", sans-serif;
}
.mobile-hamb {
  display: none;
  position: fixed;
  top: 24px;
  left: 10px;
  z-index: 999999;
}
.mobile-nav {
  display: none;
}
/* ---------------home section------------ */
.home-section {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-color);
  color: #fff;
  position: absolute;
  top: 0;
  left: 16rem;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
}
.home-container {
  display: flex;
  gap: 4rem;
  width: 100%;
  justify-content: center;
}
.avatar {
  min-width: 244px;
  min-height: 311px;
  position: relative;
  border-radius: 100%;
}
.home-img {
  background-image: url(./assets/nas.JPG);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  border-radius: 6px;
  overflow: hidden;
}
.home-description {
  display: grid;
  width: 30rem;
  align-content: center;
  gap: 1rem;
  margin-bottom: 3rem;
  padding: 0.4rem;
}
.home-description h1 {
  font-size: 4rem;
  margin: 0;
  font-weight: 800;
  font-family: 'nasalizationregular', Arial, sans-serif;
}
.home-description span {
  font-size: 1.5rem;
  font-weight: 600;
}
.home-description p {
  line-height: 1.5;
  font-size: 1.2rem;
  font-family: "Ubuntu", sans-serif;
  text-align: justify;
}
.socials {
  display: flex;
  gap: 1.3rem;
}
.socials a {
  color: #fff;
  transition: 0.3s ease-in-out;
}
.socials a:hover {
  transform: translateY(-5px);
}
/* ------------------about section-------------- */

.about-section {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-color);
  position: absolute;
  top: 0;
  left: 16rem;
  transition: .5s linear;
}
.about-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  z-index: 1;
  color: white;
}
.about-me-container {
  padding: 6rem 6rem;
}
.about-me {
  width: fit-content;
  background-color: #362d2da1;
  padding: 1rem;
}
.about-container p {
  color: var(--p-font-color);
  line-height: 1.7;
  font-size: 0.9rem;
  font-family: "Kanit", system-ui;
}
.dev-name {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.dev-name h3 {
  margin: 0;
  color: var(--font-color);
}
.dev-intro {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-top: 1px solid var(--about-border);
  border-bottom: 1px solid var(--about-border);
  padding: 1rem 0;
}
.dev-details {
  display: flex;
  gap: 10rem;
  border-bottom: 1px solid var(--about-border);
  padding-bottom: 1rem;
}
.dev-details ul {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0;
}
.dev-details ul li span:first-child {
  min-width: 100px;
  margin-right: 10px;
  font-weight: 700;
  font-size: 0.9rem;
}
.dev-details ul li span:last-child {
  font-size: 0.9rem;
  color: var(--p-font-color);
}
.left-details li {
  display: flex;
}

.right-details li {
  display: flex;
  gap: 2rem;
}
.programming-skills {
  background-color: var(--skill-bg-color);
  padding: 3rem 6rem;
}
.programming-skills h2 {
  margin-bottom: 4rem;
  color: var(--font-color);
}
.skills {
  display: flex;
  gap: 7rem;
}
.left-skills {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.left-skills label {
  float: left;
  font-size: 0.9rem;
  color: var(--skill-font);
  font-style: italic;
  margin-bottom: 8px;
}
.left-skills span {
  float: right;
  font-size: 0.9rem;
  color: var(--skill-font);
  font-style: italic;
}
.progress {
  width: 100%;
  background-color: #fff;
  height: 3px;
  float: left;
  position: relative;
}
.bar {
  position: absolute;
  height: 3px;
  width: 96%;
  background-color: #7b7b7b;
}
.js .bar {
  width: 90%;
}
.mongo .bar {
  width: 80%;
}
.html .bar {
  width: 100%;
}
.express .bar {
  width: 75%;
}

.education {
  padding: 4rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.education h3{
  color: var(--font-color);
}
.year-study {
  display: flex;
  gap: 10rem;
}
.year {
  background-color: var(--year);
  width: 7rem;
  text-align: center;
  border-radius: 50px;
  color: var(--font-color);
  letter-spacing: 1px;
  font-weight: 600;
}
.study p:first-child {
  margin: 0;
  font-weight: 600;
  color: var(--font-color);
  letter-spacing: 1px;
}
.study p:last-child {
  margin: 0;
  font-size: 0.8rem;
}

/* auto show animation */
/* .autoShow {
  animation: autoShowAnimation both;
  animation-timeline: view(70% 5%);
} */
/* @keyframes autoShowAnimation {
  from {
    opacity: 0;
    transform: translateY(200px) scale(0.3);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
} */
/* Apply the autoBlur animation only on larger screens */
/* @media (min-width: 768px) {
  .autoBlur {
    animation: autoBlurAnimation 3s ease-in-out infinite;
    animation-timeline: view();
  }
}

@keyframes autoBlurAnimation {
  0% {
    filter: blur(40px);
  }
  35%,
  60% {
    filter: blur(0px);
  }
  100% {
    filter: blur(40px);
  }
} */

/* For mobile and tablet devices, do not apply any blur or animation */
/* @media (max-width: 767px) {
  .autoBlur {
    filter: none;
    animation: none;
  }
} */

/* -----------------project section-------------------- */
.project-section {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-color);
  position: absolute;
  top: 0;
  left: 16rem;
}
.project-container {
  padding: 4rem;
}
.project-container h2{
  color: var(--font-color);
}
.my-projects {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}
.lightbox-overlay {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--bg-color);
  padding: 20px;
  border-radius: 5px;
  width: 96%;
  display: flex;
}
.lightbox-content {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.lightbox-content h1 {
  line-height: 1.5;
  text-align: center;
}
.lightbox-content button {
  border: none;
  padding: 15px 30px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  border-radius: 4px;
  font-size: 1.2rem;
  font-weight: 600;
  background-color: #4681f4;
  cursor: pointer;
  color: #fff;
}
.lightbox-content button:hover {
  background-color: #284c93;
  transform: translateY(-2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.each-project {
  cursor: pointer;
  position: relative;
}
.each-project img {
  width: 100%;
  cursor: pointer;
}
.mobile-content {
  display: none;
}
/* ----------------services section----------------- */
.service-section {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-color);
  position: absolute;
  top: 0;
  left: 16rem;
}
.service-section h2{
  color: var(--font-color);
}
.service-container {
  padding: 4rem;
}
.my-services {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 3rem;
}
.each-service {
  display: flex;
  flex-direction: column;
  background-color: var(--service-bg);
  padding: 2rem;
}
.each-service h3{
  color: var(--font-color);
}
.each-service p {
  color: var(--p-font-color);
  line-height: 1.7;
  font-size: 0.9rem;
  font-family: "Carme", sans-serif;
}
.number {
  background-color: var(--number-bg);
  width: fit-content;
  padding: 1rem;
  border-radius: 50%;
  color: var(--font-color);
  font-size: 1rem;
  font-weight: 600;
}
.facts {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  background-color: var(--facts-bg);
  padding: 4rem;
  margin-top: 4rem;
}
.fun-facts {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  text-align: center;
}
.each-fact {
  border: 1px solid var(--border);
  padding: 1rem 3rem;
}
.each-fact p {
  color: var(--p-font-color);
  line-height: 1.7;
  font-size: 0.9rem;
  font-family: "Carme", sans-serif;
}

/* --------------contact section ------------- */
.contact-section {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-color);
  position: absolute;
  top: 0;
  left: 16rem;
  width: -webkit-fill-available;
  height: 100%;
}
.contact-container {
  width: 100%;
  padding: 3rem;
}
.get-in-touch {
  display: flex;
  flex-direction: column;
}
.contact-form form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.contact-form input {
  padding: 0.6rem;
  background-color: var(--bg-color);
  border: 1px solid #6a6464;
  font-weight: 600;
  font-style: italic;
  color: var(--font-color);
}
.contact-form input::placeholder {
  color: var(--font-color);
}
.contact-form textarea {
  padding: 0.6rem;
  background-color: var(--bg-color);
  border: 1px solid #6a6464;
  font-weight: 600;
  font-style: italic;
  color: var(--font-color);
  font-size: 0.9rem;
}
.contact-form textarea::placeholder {
  color: var(--font-color);
}
.contact-form button {
  width: fit-content;
  padding: 1rem;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 1px;
  background-color: var(--font-color);
  border: none;
  border-radius: 4px;
  transition: all 0.3s ease;
  color: var(--button-color);
}
.contact-form button:hover {
  letter-spacing: 3px;
}

/* alert popup after submitting form */
#aui-flag-container {
  position: fixed;
  top: 2%;
  right: 0%;
  z-index: 4000;
}
.aui-flag-stack {
  position: relative;
}

#aui-flag-container
  > .aui-flag-stack
  > div.aui-flag-stack-top-item[aria-hidden="false"] {
  display: block;
  transition: transform 0.5s 0.5s, top 0.5s, opacity 0.8s;
}

.aui-flag[aria-hidden="false"] {
  opacity: 1;
  top: 0;
  left: 0;
}
.aui-flag {
  left: 0;
  max-height: 300px;
  opacity: 0;
  position: relative;
  top: -10px;
  transition: opacity 0.2s, top 0.5s;
}

.aui-flag .aui-message {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-radius: 3px;
  width: 200px;
}

aui-message:first-child,
.aui-message:first-child {
  margin-top: 0;
}

.aui-message {
  background: #fff;
  border: 1px solid #3572b0;
  border-radius: 3px;
  color: #333;
  line-height: 20px;
  margin: 20px 0 0 0;
  overflow-wrap: break-word;
  padding-bottom: 20px;
  padding-left: 60px;
  padding-right: 40px;
  padding-top: 20px;
  position: relative;
  word-wrap: break-word;
  word-break: break-word;
}
.aui-message.warning,
.aui-message-warning {
  background: #fff;
  border-color: #edc211;
  color: #333;
}
.aui-message.error,
.aui-message-error {
  background: #fff;
  border-color: #d04437;
  color: #333;
}

.aui-message.success,
.aui-message-success {
  background: #fff;
  border-color: #669900;
  color: #333;
}

.aui-message.success:before,
.aui-message-success:before {
  background-color: #669900;
}
.aui-message.error:before,
.aui-message-error:before {
  background-color: #d04437;
}

.aui-message.warning:before,
.aui-message-warning:before {
  background-color: #edc211;
}
.aui-message:before {
  background-color: #3572b0;
}

.aui-message:before {
  background-color: #3572b0;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  top: 0;
  width: 40px;
}

.aui-message p.title {
  font-weight: bold;
}

.aui-message p.title strong {
  font-weight: inherit;
}

.aui-message.closeable:hover .icon-close,
.aui-message.closeable .icon-close:focus,
.aui-message.closeable:not(.fadeout):not(.aui-will-close) .icon-close {
  opacity: 1;
}

.aui-message.closeable .icon-close {
  top: 10px;
}

.aui-message.closeable .icon-close {
  cursor: pointer;
  left: auto;
  opacity: 0;
  position: absolute;
  right: 20px;
  top: 20px;
}

.aui-message .aui-icon.icon-close {
  background-image: none;
  color: #707070;
  text-indent: inherit;
}

.aui-message .aui-icon {
  background-position: center;
}

.aui-icon {
  background-repeat: no-repeat;
  border: none;
  display: inline-block;
  height: 16px;
  margin: 0;
  padding: 0;
  text-align: left;
  /*text-indent: -999em;*/
  vertical-align: text-bottom;
  width: 16px;
}

.aui-message.success:after,
.aui-message-success:after {
  content: "\f05d";
  color: #fff;
}
.aui-message.warning:after,
.aui-message-warning:after {
  content: "\f071";
  color: #fff;
}
.aui-message.error:after,
.aui-message-error:after {
  content: "\f06a";
  color: #fff;
}

.aui-message:after {
  content: "\f05a";
  color: #fff;
}

.aui-message:after {
  color: #fff;
  font-family: "FontAwesome";
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: normal;
  left: 12px;
  line-height: 1;
  margin-top: -8px;
  position: absolute;
  /* speak: none; */
  top: 50%;
}

/* mobile section */
@media only screen and (max-width: 1080px) {
  .side-nav-container {
    display: none;
  }
  .home-section {
    left: 0;
    display: grid;
    text-align: center;
    height: fit-content;
  }
  .home-container {
    display: grid;
    margin-top: 8rem;
    justify-items: center;
  }
  .mobile-nav {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    align-items: center;
    justify-content: center;
    position: fixed;
    /* left: -104%; */
    z-index: 3;
    width: 16rem;
    height: 100vh;
    background-color: var(--sidenav-bg);
    text-align: center;
  }
  .avatar {
    width: 200px;
    min-height: 242px;
  }
  .home-description {
    justify-items: center;
    width: auto;
  }
  .mobile-hamb {
    display: flex;
  }
  .about-section {
    left: 0;
    width: 100%;
  }
  .about-container {
    width: 100%;
    margin-top: 5rem;
  }
  .about-me-container {
    width: 100%;
    text-align: center;
    padding: 0;
  }
  .dev-intro {
    padding: 1rem;
  }
  .dev-details {
    display: grid;
    text-align: left;
    padding-left: 14px;
    gap: 0;
  }
  .left-details ul li span:first-child {
    margin-right: 43px;
  }
  .my-services {
    grid-template-columns: 1fr 1fr;
  }
  .service-section {
    left: 0;
  }
  .contact-section {
    left: 0;
    align-items: unset;
    /* height: 100%; */
  }
  .project-section {
    left: 0;
  }
  .home-img {
    border-radius: 50%;
  }
}

@media only screen and (max-width: 680px) {
  .skills {
    display: grid;
    gap: 2rem;
  }
  .year-study {
    justify-content: center;
  }
  .year {
    height: fit-content;
    background-color: transparent;
  }
  .service-section {
    width: 100%;
    left: 0;
  }
  .service-container {
    width: 100%;
    margin-top: 5rem;
    padding: 2rem;
  }
  .my-services {
    grid-template-columns: 1fr;
    gap: 0;
  }
  .each-service {
    margin: 1rem;
  }
  .fun-facts {
    display: grid;
    gap: 3rem;
  }
  .lightbox-overlay {
    position: relative;
    left: 0;
    transform: none;
    background-color: transparent;
    width: auto;
  }
  .mobile-content {
    display: flex !important;
  }
  .lightbox-content h1 {
    line-height: 1.2;
    font-size: 1.3rem;
    font-weight: 100;
  }
  .home-img {
    border-radius: 50%;
  }
}
